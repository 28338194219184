<template>
	<div class="doc-container" :style="{ transform: `translateY(${limitedScrollY}px)` }">
        <iframe :src="fileUrl" height="100%" width="100%" style="border: none;"></iframe>
    </div>
</template>

<script>
    export default {
        name: 'PreviewPane',

        props: {
            fileUrl: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
                scrollY: 0,          // Holds the actual scroll position
                maxTranslate: -100,   // Maximum translateY value (float up to -50px)
            };
        },
        methods: {
            handleScroll() {
                this.scrollY = window.scrollY; // Update the scroll position
            }
        },
        computed: {
            limitedScrollY() {
            // Limit the scroll effect to max -50px (element floats up to 50px only)
                return Math.max(this.scrollY * -0.5, this.maxTranslate);
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll); // Register scroll event
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.handleScroll); // Cleanup
        },
    };
</script>

<style scoped>
.doc-container {
    position: fixed;
    padding-right: 10px;
    right: 0px;
    height: calc(100vh - 62px);
    aspect-ratio: 3 / 4;
}
</style>