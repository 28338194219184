<template>
  <div class="search-results-page">
    <!-- Top search bar to re-search -->
    <div class="head-container">
      <div class="logo" style="position: absolute; top: 20px; padding: 0px 28px 0 0px;">
        <a href="/">
          <img src="../assets/logo_rect.png" width="200px" height="50px">
        </a>
      </div>
      <SearchBar :query="query" />
    </div>
    <div class="results-container" @contextmenu.prevent="showContextMenu($event)" @click="hideContextMenu">
      <div class="center">
        <loading v-model:active="isLoading" :lock-scroll=false />
        <!-- List of search results -->
        <SearchResult v-for="result in searchResults" :key="result.id" :id="result.id" :mimeType="result.mimeType"
          :title="result.name" :createdTime="result.createdTime" :parents="result.parents" @click="previewFile(result.id, result.mimeType, result.name)"/>
        <input hidden id="nextToken" v-bind:value="nextToken">
      </div>
      <PreviewPane :fileUrl="file" />

        <!-- Context Menu -->
      <transition name="fade">
        <ul
          v-if="isMenuVisible"
          class="context-menu"
          :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }"
        >
          <li @click="previewFile(target.getElementsByClassName('id')[0].value, target.getElementsByClassName('mime-type')[0].value, target.getElementsByClassName('result-title')[0].innerText)">Preview</li>
          <li @click="downloadFile(target.getElementsByClassName('id')[0].value, target.getElementsByClassName('mime-type')[0].value, target.getElementsByClassName('result-title')[0].innerText)">Download</li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import SearchResult from '@/components/SearchResult.vue';
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import PreviewPane from '@/components/PreviewPane.vue';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  components: {
    SearchBar,
    SearchResult,
    PreviewPane,
    Loading,
  },

  props: {
    query: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: this.query, // Bind search input with the current query
      searchResults: [],
      nextToken: undefined,
      file: undefined,
      isLoading: false,
      isMenuVisible: false,
      menuPosition: { x: 0, y: 0 },
      target: undefined,
    };
  },
  beforeMount() {
    this.loadInitialBatch(this.query);
  },
  mounted() {
    this.loadNextBatch(this.query);
  },
  watch: {
    query(newQuery) {
      // Whenever the route query changes, perform the search again
      this.searchQuery = newQuery;
      this.loadInitialBatch(newQuery);
    },
  },
  methods: {
    loadInitialBatch(query) {
      this.isLoading = true;
      axios.get(''.concat("/search?q=", encodeURIComponent(query))).then((res) => {
        this.searchResults = res.data.files;
        this.nextToken = res.data.next_page_token;
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        console.log(error);
        this.$router.push("/")
      }
      );
    },
    loadNextBatch(query) {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        let token = document.getElementById('nextToken').value;
        
        if (bottomOfWindow) {
          this.isLoading = true;
          // axios.get(''.concat("https://api.se4rch.online/search/?q=", encodeURIComponent(query), "&next_token=", encodeURIComponent(token))).then((res) => {
            axios.get(''.concat("/search?q=", encodeURIComponent(query), "&next_token=", encodeURIComponent(token))).then((res) => {
            res.data.files.forEach(file => {
              this.searchResults.push(file);
            });
            this.isLoading = false;
            this.nextToken = res.data.next_page_token;
          })
          .catch((error) => {
              console.log(error);
              return [];
            }
          );
        }
      }
    },
    previewFile(fileId, mimeType, title) {
      this.isMenuVisible = false;
      this.isLoading = true;
      axios.post("/preview", 
        JSON.stringify({
          id : fileId,
          mimeType : mimeType,
          name: title,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      ).then(res => {
        let url = encodeURIComponent(''.concat('https://api.se4rch.online', res.data.url, '&token=', this.$store.getters.token)); 
        this.file = ''.concat("https://docs.google.com/gview?url=", url, '&embedded=true');
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        this.file = 'https://nofile.com';
        console.log(error);
      });
    },

    async downloadFile(fileId, mimeType, title) {
      this.isMenuVisible = false;
      this.isLoading = true;
      var response = await axios.post("/download", 
        JSON.stringify({
          id : fileId,
          mimeType : mimeType,
          name: title,
        }),
        {
          headers: {
              'Content-Type': 'application/json',
          },
          responseType: "blob"
        }
      ).catch((error) => {
        console.log(error);
      });
      this.isLoading = false;
      this.forceDownload(response, title)
    },
    forceDownload(response, title) {
      console.log(''.concat("Downloading ", title));
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    showContextMenu(event) {
      this.isMenuVisible = true;
      this.menuPosition = { x: event.pageX, y: event.pageY };
      this.target = event.target.parentNode;
    },
    hideContextMenu() {
      this.isMenuVisible = false;
    },
  },
};
</script>

<style scoped>
/* Top search bar styling */
.head-container {
  display: flex;
  margin: 20px 0;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1128px;
}

.search-bar {
  position: relative;
  flex: 0 auto;
  margin-left: 180px;
}

/* Search results styling */
.results-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 100vw;
  border-top: 1px solid #dfe1e5;
  padding-top: 20px;
  background-color: white;
}

.results-container .center {
  position: relative;
  width: 586px;
  flex: 0 auto;
  margin-left: 180px;
}

.search-result {
  margin-bottom: 30px;
}

.search-result div:hover {
  cursor: pointer;
}

.result-title {
  display: block;
  color: #1a0dab;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.result-title:hover {
  text-decoration: underline;
}

.result-url {
  color: #006621;
  font-size: 14px;
}

.result-snippet {
  color: #4d5156;
  font-size: 14px;
}
.main-content {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  font-size: 1.2rem;
}

/* Context Menu Styling */
.context-menu {
  position: absolute;
  list-style: none;
  padding: 10px 0;
  margin: 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  width: 180px;
  z-index: 1000;
  border: 1px solid #ddd;
  transition: all 0.3s ease;
}

.context-menu li {
  padding: 10px 15px;
  cursor: pointer;
  transition: background 0.2s;
}

.context-menu li:hover {
  background-color: #f0f0f0;
}

/* Fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
