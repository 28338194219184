<template>
    <div class="logo-search">
        <img
        class="logo"
        src="../assets/logo_rect.png"
        alt="Logo"
        width="400px"
        />
        <SearchBar />
    </div>
</template>
  
<script>
    import SearchBar from '@/components/SearchBar.vue';
    
    export default {
        components: {
            SearchBar
        }
    };
</script>
  
<style scoped>
    .logo-search {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 60vh;
        background-color: white;
    }

    .logo {
        margin-bottom: 40px;
    }
</style>
  