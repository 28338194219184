<template>
    <div class="search-bar">
        <input
            v-model="searchQuery"
            type="text"
            class="search-input"
            placeholder="Search keywords"
            autofocus
            autocomplete="off"
            autocorrect="off"
            maxlength="2048"
            @keyup.enter="search"
        />
        <div class="search-button" role="button" @click="search">
            <div class="svg-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchBar",
    props: {
        query: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            searchQuery: this.query,
        }
    },
    methods: {
        search() {
            if (this.searchQuery.trim()) {
                // Navigate to the same results page but with a new query
                this.$router.push({ path: '/search', query: { q: this.searchQuery } });
            } else {
                alert('Please enter a search term');
            }
        },
    }
}
</script>

<style>
    .search-bar {
        display: flex;
        align-items: center;
        border: 1px solid #dfe1e5;
        background-color: white;
        border-radius: 24px;
    }

    .search-input {
        width: 500px;
        height: 20px;
        padding: 10px;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        border: 0px solid #dfe1e5;
        font-size: 16px;
        outline: none;
        transition: box-shadow 0.2s ease-in-out;
    }

    .search-button {
        position: relative;
        padding: 10px 20px;
        background-color: white;
        border: none;
        border-radius: 24px;
        color: white;
        cursor: pointer;
        outline: none;
    }
</style>