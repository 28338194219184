import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';

axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'http://localhost:8000/';
axios.defaults.baseURL = 'https://api.se4rch.online/';

// NEW
axios.interceptors.response.use((response) => {return response}, (error) => {
  if (error.response) {
    // const originalRequest = error.config;
    if (error.response.status === 401) {
      // originalRequest._retry = true;
      store.dispatch('logout').then(() => {return router.push('/login')})
    }
  }
  return Promise.reject(error);

});

createApp(App)
  .use(router)
  .use(store)
  .mount('#app')
