
const state = {
  isAuthenticated: !!localStorage.getItem('authToken'), // Check if token exists on reload
  token: localStorage.getItem('authToken') || null,
  user: null,
}

const mutations = {
  setAuth(state, { token, user }) {
    state.isAuthenticated = true;
    state.token = token;
    state.user = user;
    localStorage.setItem('authToken', token); // Persist token in localStorage
  },
  clearAuth(state) {
    state.isAuthenticated = false;
    state.token = null;
    state.user = null;
    localStorage.removeItem('authToken'); // Remove token on logout
  },
}

const actions = {
  login({ commit }, { token, user }) {
    commit('setAuth', { token, user });
  },
  logout({ commit }) {
    commit('clearAuth');
  },
}

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  user: (state) => state.user,
  token: (state) => state.token,
}

export default {
  state,
  getters,
  actions,
  mutations
};
  

  