import { createWebHistory, createRouter } from 'vue-router'
import SearchPage from '../views/SearchPage.vue';
import SearchResults from '../views/SearchResults.vue';
import axios from 'axios';
import store from '@/store'
import Login from '@/views/LoginView.vue';

const routes = [
  {
    path: '/',
    name: 'SearchPage',
    component: SearchPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: SearchResults,
    props: (route) => ({ query: route.query.q }), // Passing search query as a prop
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'LoginView',
    component: Login,
  },
  {
    path: '/:catchAll(.*)', // Catch-all route for undefined paths
    redirect: '/', // Redirect to the home page
  },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
  })

router.beforeEach((to, _, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated === false) {
      next('/login');
    } else {
      axios.defaults.headers.Authorization = `Bearer ${store.getters.token}`
      next();
    } 
  } else {
    if (to.matched.some(record => record.path === "/login") && store.getters.isAuthenticated === true) next("/");
    else next();
  }
});
  
export default router;
