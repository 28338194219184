<template>
    <div class="login-container">
      <img
        src="../assets/logo.png"
        class="logo"
      />
  
      <!-- Login form -->
        <div class="login-form" @keyup.enter="login">
            <h1>Sign in</h1>

            <p v-show="wrongCred" style="color: red;">Wrong credential</p>
    
            <!-- Email input -->
            <div class="input-group">
            <input
                v-model="form.username"
                type="text"
                placeholder="Email or phone"
                class="input-field"
                required
            />
            </div>
    
            <!-- Password input -->
            <div class="input-group">
            <input
                v-model="form.password"
                type="password"
                placeholder="Enter your password"
                class="input-field"
                required
            />
            </div>
    
            <!-- Login button -->
            <button @click="login" class="login-button">Login</button>
        </div>
    </div>
  </template>
  
<script>
    // import { setAuthState } from '@/auth';
  import axios from 'axios';
    
    export default {
        name: 'LoginView',
        data() {
            return {
                form: {
                    username: '',
                    password:'',
                },
                wrongCred: false,
            };
        },
        methods: {
          async login() {
            const User = new FormData();
            User.append('username', this.form.username);
            User.append('password', this.form.password);
            try {
              var res = await axios.post('/login', User);
              if (res.status === 200){
                const token = res.data.token;
                const user = res.data.user;
                this.$store.dispatch('login', { token, user });
                this.$router.push("/");
              } else {
                this.wrongCred = true
                console.log("Log in failed.")
              }
            } catch(error) {
              this.wrongCred = true
              console.log(error);
            }
          }
        },
    };
</script>

<style scoped>
/* Basic styling for the login page */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.logo {
  width: 300px;
  margin-bottom: 20px;
}

.login-form {
  position: relative;
  top: -100px;
  width: 300px;
  padding: 20px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  text-align: center;
}

.login-form h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.login-form p {
  font-size: 14px;
  color: #5f6368;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 16px;
}

.input-field {
  width: 90%;
  padding: 10px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 16px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #1a73e8;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 16px;
}

.login-button:hover {
  background-color: #1669c7;
}
</style>
