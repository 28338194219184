<template>
    <div class="search-result">
        <div class="avatar">
            <img v-if="mimeType === 'application/pdf'" src="../assets/pdf.png" width="50px">
            <img v-else-if="mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" src="../assets/doc.png" width="50px">
            <img v-else-if="mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" src="../assets/xls.png" width="50px">
            <img v-else-if="mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'" src="../assets/ppt.png" width="50px">
            <img v-else-if="mimeType === 'text/plain'" src="../assets/txt.png" width="50px">
            <img v-else src="../assets/document.png" width="50px">
        </div>
        <div class="metadata">
            <div class="result-title">{{ title }}</div>
            <div class="created-time">{{ new Date(createdTime).toLocaleDateString() }}</div>
            <input class="mime-type" hidden :value="mimeType">
            <input class="id" hidden :value="id">
        </div>
    </div>
</template>

<script>
    export default {
        name: "SearchResult",
        props: {
            id: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            mimeType: {
                type: String,
                required: true,
            },
            createdTime: {
                type: String,
                required: true,
            },
            parents: {
                type: Array,
                required: false,
            }
        }
    }
</script>

<style>
    .search-result {
        display: flex;
        margin-bottom: 30px;
    }
    
    .search-result div:hover {
        cursor: pointer;
    }
    
    .result-title {
        display: block;
        color: #1a0dab;
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;
    }
    
    .result-title:hover {
        text-decoration: underline;
    }
    
    .result-url {
        color: #006621;
        font-size: 14px;
    }
    
    .result-snippet {
        color: #4d5156;
        font-size: 14px;
    }

    .metadata {
        padding-left:10px; 
        padding-top: 8px;
        padding-bottom: 8px;
    }
</style>